<template>
    <div class="flex flex-col gap-3 md:gap-5 xl:gap-8 group">
        <nuxt-link :to="item?.url" class="relative col-flex image-product-container">
            <div class="aspect-1/1 b-0.5 b-gray-200 overflow-hidden w-full">
                <NuxtImg
                    :src="getThumbnail(currentProduct?.images[0], 500, true)"
                    width="500"
                    sizes="sm:100vw md:50vw lg:500px "
                    loading="lazy"
                    alt="Banner Collection Detail"
                    :placeholder="[50, 25, 75, 5]"
                    class="w-full h-full object-cover transform duration-400 group-hover:scale-110"
                />
            </div>
        </nuxt-link>
        <div class="flex-1 flex flex-col gap-2">
            <!-- <pre>{{ item }}</pre> -->
            <div class="h-8.5">
                <div
                    v-if="item?.attributes && item?.attributes?.color && item?.attributes?.color.length > 0"
                    class="flex items-center gap-2"
                >
                    <!-- <div
                        @click="changeCurrentImg(item?.icon)"
                        v-for="item in item.attributes?.color"
                        :class="currentColor == item?.icon ? 'active' : ''"
                        class="color-select-product w-8.5 h-8.5 cursor-pointer b-0.5 b-gray-400"
                    >
                        <img :src="getThumbnail(item?.icon, 100, true)" alt="Icon Color" />
                    </div> -->
                    <div
                        v-for="item in item.attributes?.color"
                        class="color-select-product w-8.5 h-8.5 b-0.5 b-gray-400"
                    >
                        <img :src="getThumbnail(item?.icon, 100, true)" alt="Icon Color" />
                    </div>
                </div>
                <div
                    v-if="
                        (item?.attributes?.color.length == 0 && !currentProduct?.iconId) ||
                        (!item?.color && !item?.attributes)
                    "
                    class="w-8.5 h-8.5 b-0.5 b-gray-400 col-flex bg-white box-not-color"
                ></div>
                <div v-if="!item?.attributes && !item?.attributes?.color && currentProduct?.iconId">
                    <div class="color-select-product w-8.5 h-8.5 b-0.5 b-gray-400">
                        <img :src="getThumbnail(currentProduct?.iconId, 100, true)" alt="Icon Color" />
                    </div>
                </div>
            </div>
            <div class="flex-1 flex flex-col gap-2 md:gap-3">
                <nuxt-link
                    :to="item.url"
                    class="text-blacks-100 hover:underline duration-400 text-lg font-medium line-clam-3"
                    >{{ item.title }}</nuxt-link
                >
                <p v-if="currentProduct?.quick_detail" class="text-xs md:text-sm xl:text-base text-blacks-70">
                    {{ currentProduct?.quick_detail }}
                </p>
                <p v-if="currentSku" class="text-10px md:text-sm text-blacks-70">
                    {{ currentProduct?.sku }}
                </p>
                <div v-if="currentPrice" class="flex items-start gap-1 md:gap-2 mt-auto">
                    <p class="text-lg lg:text-xl font-medium text-blacks-100">{{ currentProduct?.price }}</p>
                    <p class="text-sm">VNĐ</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const props = defineProps({
    item: {
        type: Object as any
    }
})
const currentProduct = ref()
const checkNotAttribute = props.item?.attributes?.color.length > 0 ? props.item?.attributes?.color[0] : props.item

const currentImg = ref(checkNotAttribute?.images[0] ?? '')
const currentColor = ref(checkNotAttribute?.icon ?? '')
const currentQuickDetail = ref(props.item?.attributes?.color[0]?.quick_detail ?? '')
const currentSku = ref(checkNotAttribute?.sku ?? '')
const currentPrice = ref(checkNotAttribute?.price ?? '')
const changeCurrentImg = (icon: string) => {
    return props?.item?.attributes?.color?.find((item: any) => {
        if (item?.icon == icon) {
            currentColor.value = icon
            currentImg.value = item?.images[0]
            currentQuickDetail.value = item?.quick_detail
            currentSku.value = item?.sku
            currentPrice.value = item?.price
        }
    })
}
onMounted(() => {
    if (
        (props.item?.attributes?.color.length > 0 && props.item?.attributes?.size.length > 0) ||
        (props.item?.attributes?.color.length > 0 && props.item?.attributes?.size.length <= 0)
    ) {
        const productDefault = props.item?.attributes?.color[0]

        currentProduct.value = {
            id: productDefault.variants[0]?.product?.id,
            iconId: productDefault.icon ?? '',
            iconTitle: productDefault.title ?? '',
            sku: productDefault.sku ?? '',
            images: productDefault?.variants[0]?.product?.images ?? [],
            price: productDefault.price ?? '',
            quick_detail: productDefault.quick_detail ?? '',
            list_size: productDefault.variants ?? [],
            prominent_features: productDefault?.variants[0]?.product?.prominent_features
        }
    } else if (props.item?.attributes?.color.length <= 0 && props.item?.attributes?.size.length > 0) {
        const productDefault = props.item?.attributes?.size[0]
        const listSize = props.item?.attributes?.size.map((item) => {
            return {
                size: item.size,
                product: item?.variants[0].product
            }
        })

        currentProduct.value = {
            id: productDefault.variants[0]?.product?.id,
            iconId: '',
            iconTitle: '',
            sku: productDefault.sku ?? '',
            images: productDefault.images ?? [],
            price: productDefault.price ?? '',
            quick_detail: productDefault.quick_detail ?? '',
            list_size: listSize ?? [],
            prominent_features: productDefault?.variants[0]?.product?.prominent_features
        }
    } else {
        const productDefault = props.item
        currentProduct.value = {
            id: productDefault?.id,
            iconId: productDefault?.color?.icon ?? '',
            iconTitle: productDefault?.color?.title ?? '',
            sku: productDefault.sku ?? '',
            images: productDefault.images ?? [],
            price: productDefault.price ?? '',
            quick_detail: productDefault.quick_detail ?? '',
            list_size: [productDefault?.size] ?? [],
            prominent_features: productDefault?.prominent_features
        }
    }
})
</script>

<style scoped>
.color-select-product.active {
    @apply b-2 b-blacks-90 shadow-xl relative;
}
.color-select-product.active:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 1px solid white;
}
.box-not-color {
    @apply relative overflow-hidden bg-gray-50/70;
}
.box-not-color:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 150%;
    @apply bg-gray-200;

    transform: rotate(45deg);
}
.box-not-color:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 150%;
    @apply bg-gray-200;
    transform: rotate(-45deg);
}
</style>
